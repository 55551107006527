import gaEventBus from './google';
import fbEventBus from './fbevents';
import gtm from './gtm';
import clarity from './clarity';
import moengageEventBus from './moengage';
import enabledBuses from '@/app_config/analytics';
import {
  getCookie,
  getUtmSourceFromHost,
  isMicroappsEnabled,
} from '@/lib/utils';
import { get } from '@/lib/helpers';
import { store } from '@/store';

/**
 * Emits events to all analytic buses
 * @param {String} eventName
 * @param {any} eventAttributes
 */
export function emitEvents(eventName: string, eventAttributes: any = {}) {
  const deviceId = getCookie('deviceId');
  const utmSource = getCookie('utm_source');
  const state = store.getState();

  if (deviceId) {
    (eventAttributes as any).deviceId = deviceId;
  }
  if (state) {
    (eventAttributes as any).phone =
      eventAttributes.phone ||
      get(state as object, 'customer.customerDetails.phone', '');
  }
  if (utmSource) {
    (eventAttributes as any).utmSource = utmSource;
  } else {
    if (isMicroappsEnabled()) {
      (eventAttributes as any).utmSource =
        getUtmSourceFromHost() || process.env.VUE_APP_GPAY_APP_ID;
    } else {
      (eventAttributes as any).utmSource =
        getUtmSourceFromHost() || process.env.VUE_APP_ID;
    }
  }

  if (enabledBuses.google) {
    gaEventBus.$emit('processEvent', eventName, eventAttributes);
  }
  if (enabledBuses.facebook) {
    fbEventBus.$emit('processEvent', eventName, eventAttributes);
  }
  if (enabledBuses.gtm) {
    gtm.$emit('processEvent', eventName, eventAttributes);
  }
  if (enabledBuses.clarity) {
    clarity.$emit('processEvent', eventName, eventAttributes);
  }
  if (enabledBuses.moengage) {
    moengageEventBus.$emit('processEvent', eventName, eventAttributes);
  }
}

export function captureUniqueId(customerId: string) {
  if (enabledBuses.moengage) {
    moengageEventBus.$emit('capture-id', customerId);
  }
}

export function captureUserInfo(userInfo: Record<string, any>) {
  if (enabledBuses.moengage) {
    moengageEventBus.$emit('capture-user-info', userInfo);
  }
}

export function captureQualFlowType(qualFlowType: string) {
  if (enabledBuses.moengage) {
    moengageEventBus.$emit('capture-qual-flow-type', qualFlowType);
  }
}

export function logoutSession() {
  if (enabledBuses.moengage) {
    moengageEventBus.$emit('user-logout');
  }
}
