import qs from 'qs';
import bus from './event_bus';
import { isEmpty, head, get, hasOwnProperty } from '@/lib/helpers';
import { partnerBankConfig } from '@/app_config/partner_bank_config';
import {
  cityRegionMapping,
  customerProfileReference,
  INPUT_DATE_REGEX,
  IND_PHONE_REGEX,
} from './consts';
import { cities } from '@/app_config';
import { tenure } from '@/app_config/qual_config';
import router from '@/router';
import { store } from '@/store/index';
import { Tenure } from '@/interfaces/app_config';
import { isCustomerAuthenticated } from './login_utils';
import { authState } from '@/store/getters/auth';
import { retrieveBigCookie } from '@/store/actions/customer';
import ApplicationState from '@/business/models/application_state';
import LoanResponse from '@/business/models/loan_response';
import {
  clearAccessDetails,
  doCtxLogout,
  refreshAccessToken,
} from '@/store/actions/auth';
import { layoutConfig } from '@/store/getters/app_config';
import { ACCESS_DETAILS } from '@/store/store_keys';
import { deleteLocalKey } from '@/store/local_store';
import { navigateTo } from './layout_config';
import CreditLineManager from '@/business/manager/credit_line_manager';
import {
  ApplicationStatusCodeComponent,
  BinaryPrecision,
} from '@/enums/application_state';
import { updateGeolocation } from '@/store/actions/app_config';
import { HOST_TO_APP_SOURCE_MAPPING } from './app_consts';

declare const microapps: any;

export function getUrlParam(url: string, paramKey: string): string {
  const queryParams = qs.parse(url, { ignoreQueryPrefix: true });
  if (hasOwnProperty(queryParams, paramKey)) {
    return queryParams[paramKey];
  }
  return '';
}

export function trackAips(loanResponse: any) {
  const { bankName = '' } = loanResponse;
  if (bankName) {
    bus.$emit('syncEvent', `WEB.AIP.${bankName}`, {
      bankName,
      ...loanResponse,
      nonInteraction: true,
    });
  }
}

export function getCookie(key: string) {
  const cookieString = document.cookie;
  if (cookieString.length > 0) {
    const cookieValue = cookieString.match(new RegExp(key + '=([^;]+)'));
    if (!cookieValue) {
      return '';
    }
    return decodeURIComponent(cookieValue[1]);
  }
}

export function setCookie(trackParams: any, expiryDate: any, validDomain = '') {
  const domainString = validDomain ? `; domain=${validDomain}` : '';
  let cookieExpiryDate = 30 * 24 * 60 * 60;
  if (isNaN(expiryDate)) {
    cookieExpiryDate = expiryDate;
  }
  if (!isEmpty(trackParams)) {
    const trackParmeters: any = Object.entries(trackParams);
    for (const [key, value] of trackParmeters) {
      document.cookie = `${key}=${encodeURIComponent(
        value
      )}; max-age=${cookieExpiryDate}; path=/${domainString}`;
    }
  }
}

export function getAllCookies(): any {
  const pairs = document.cookie.split(';');
  const cookies = {};
  // tslint:disable-next-line: prefer-for-of
  for (let idx = 0; idx < pairs.length; idx++) {
    const pair = pairs[idx].split('=');
    (cookies as any)[(head(pair) + '').trim()] = unescape(pair[1]);
  }
  return cookies;
}

export function getCurrentPartnerCode(customer: any) {
  if (customer) {
    if (!isEmpty(customer.applicationState)) {
      return customer.applicationState.currentPartnerCode;
    }
  }
  return '';
}

export function getProductFeatures(customer: any) {
  const partnerCode = getCurrentPartnerCode(customer);
  if (partnerCode) {
    const partnerConfig: any = partnerBankConfig;
    return partnerConfig[partnerCode].productOfferings[0].productFeatures || [];
  }
  return [];
}

export function getApplicableCities(state: any) {
  const customer = get(state, 'customer');
  if (customer) {
    const partnerCode = getCurrentPartnerCode(customer);
    if (partnerCode) {
      const supportedCities: any = get(
        partnerBankConfig,
        `${partnerCode}.supportedCities`
      );
      const currentCity: string = get(customer, `customerDetails.currentCity`);
      const cityRegion: string = cityRegionMapping[currentCity];
      const cityStateMaps: any = cities;
      const citiesList: string[] = [];
      for (const city in cityRegionMapping) {
        if (
          cityRegion === cityRegionMapping[city] &&
          supportedCities.includes(city)
        ) {
          citiesList.push(city);
        }
      }
      return cityStateMaps.filter((elem: any) =>
        citiesList.includes(elem.city)
      );
    }
  }
  return [];
}

export function getNationalityMappings(customer: any) {
  const partnerCode = getCurrentPartnerCode(customer);
  if (partnerCode) {
    const partnerConfig: any = partnerBankConfig;
    return partnerConfig[partnerCode].nationalityMap || {};
  }
  return {};
}

export function getEducationQualificationMappings(customer: any) {
  const partnerCode = getCurrentPartnerCode(customer);
  if (partnerCode) {
    const partnerConfig: any = partnerBankConfig;
    return partnerConfig[partnerCode].educationQualificationMap || {};
  }
  return {};
}

export function getDocumentGroup(partnerCode: string, documentType: string) {
  const partnerConfig: any = partnerBankConfig;
  return partnerConfig[partnerCode].documentGroups[documentType] || {};
}

export function getFileExtensionFromMime(mimeType: string) {
  const mimeTypeMapping: { [key: string]: string } = {
    'image/png': 'png',
    'image/jpeg': 'jpeg',
    'image/jpg': 'jpg',
    'image/svg': 'svg',
    'image/tiff': 'tiff',
    default: 'png',
  };
  return mimeTypeMapping[mimeType || 'default'];
}

export function handleRedirection(payload: {
  source: string;
  routeName: string;
  action: string;
  actionableType?: string;
  params?: Record<string, string> | null;
  query?: Record<string, string> | null;
}) {
  const {
    source,
    routeName,
    action,
    actionableType = 'ESIGN',
    params = {},
    query = {},
  } = payload;
  switch (source) {
    case 'WEBAPP':
      redirectToApp(action, actionableType);
      // eslint-disable-next-line no-case-declarations
      const routeMeta: any = {
        name: routeName,
        params: !isEmpty(params) ? params : {},
        query: !isEmpty(query) ? query : {},
      };

      router.push(routeMeta);
      break;
    case 'ANDROID':
    case 'IOS':
      redirectToApp(action, actionableType);
      setTimeout(() => {
        store.dispatch(clearAccessDetails());
        deleteLocalKey(ACCESS_DETAILS);
      }, 1000);
      break;
    default:
      router.push({
        name: get(layoutConfig(), `routes.${routeName}`, routeName),
      });
      break;
  }
}

export function redirectToApp(action: string, actionableType: string) {
  const actionTypeMapping: Record<string, Record<string, any>> = {
    ESIGN: {
      success: 'esign_return_url',
      error: 'esign_error_url',
      cancel: 'esign_cancel_url',
      navigate(redirectUrl: string) {
        if ((window as any).ESignJavascriptInterface) {
          (window as any).ESignJavascriptInterface.navigateToUrl(
            'xxx/' + redirectUrl
          );
        } else if (
          (window as any).webkit &&
          (window as any).webkit.messageHandlers
        ) {
          try {
            (
              window as any
            ).webkit.messageHandlers.ESignJavascriptInterface.navigateToUrl(
              'xxx/' + redirectUrl
            );
          } catch (err) {
            console.log('Error calling interface: ', err);
          }
          try {
            (
              window as any
            ).webkit.messageHandlers.ESignJavascriptInterface.postMessage(
              redirectUrl
            );
          } catch (err) {
            console.log('Error calling interface: ', err);
          }
        }
      },
    },
    OKYC: {
      success: 'okyc_return_url',
      error: 'okyc_error_url',
      cancel: 'okyc_cancel_url',
      upload_kyc: 'okyc_manual_upload_url',
      navigate(redirectUrl: string) {
        if ((window as any).OkycJavascriptInterface) {
          (window as any).OkycJavascriptInterface.navigateToUrl(
            'xxx/' + redirectUrl
          );
        } else if (
          (window as any).webkit &&
          (window as any).webkit.messageHandlers
        ) {
          try {
            (
              window as any
            ).webkit.messageHandlers.OkycJavascriptInterface.navigateToUrl(
              'xxx/' + redirectUrl
            );
          } catch (err) {
            console.log('Error calling interface: ', err);
          }
          try {
            (
              window as any
            ).webkit.messageHandlers.OkycJavascriptInterface.postMessage(
              redirectUrl
            );
          } catch (err) {
            console.log('Error calling interface: ', err);
          }
        }
      },
    },
    LOAN_RESTRUCTURE: {
      success: 'loan_restructure_return_url',
      error: 'loan_restructure_error_url',
      cancel: 'loan_restructure_cancel_url',
      faq: 'loan_restructure_faq_url',
      navigate(redirectUrl: string) {
        if ((window as any).LoanRestructureJavascriptInterface) {
          (window as any).LoanRestructureJavascriptInterface.navigateToUrl(
            'xxx/' + redirectUrl
          );
        } else if (
          (window as any).webkit &&
          (window as any).webkit.messageHandlers
        ) {
          try {
            (
              window as any
            ).webkit.messageHandlers.LoanRestructureJavascriptInterface.navigateToUrl(
              'xxx/' + redirectUrl
            );
          } catch (err) {
            console.log('Error calling interface: ', err);
          }
          try {
            (
              window as any
            ).webkit.messageHandlers.LoanRestructureJavascriptInterface.postMessage(
              `xxx/${redirectUrl}`
            );
          } catch (err) {
            console.log('Error calling interface: ', err);
          }
        }
      },
    },
    KARZA: {
      return: 'karza_return_url',
      cancel: 'karza_cancel_url',
      error: 'karza_error_url',
      faq: 'karza_faq_url',
      navigate(redirectUrl: string) {
        if ((window as any).KarzaJavascriptInterface) {
          (window as any).KarzaJavascriptInterface.navigateToUrl(
            'xxx/' + redirectUrl
          );
        } else if (
          (window as any).webkit &&
          (window as any).webkit.messageHandlers
        ) {
          try {
            (
              window as any
            ).webkit.messageHandlers.KarzaJavascriptInterface.navigateToUrl(
              'xxx/' + redirectUrl
            );
          } catch (err) {
            console.log('Error calling interface: ', err);
          }
          try {
            (
              window as any
            ).webkit.messageHandlers.KarzaJavascriptInterface.postMessage(
              `xxx/${redirectUrl}`
            );
          } catch (err) {
            console.log('Error calling interface: ', err);
          }
        }
      },
    },
    DEFAULT: {
      default: '',
      navigate(redirectUrl: string) {
        if ((window as any).ESignJavascriptInterface) {
          (window as any).ESignJavascriptInterface.navigateToUrl(
            'xxx/' + redirectUrl
          );
        } else if (
          (window as any).webkit &&
          (window as any).webkit.messageHandlers
        ) {
          try {
            (
              window as any
            ).webkit.messageHandlers.ESignJavascriptInterface.navigateToUrl(
              'xxx/' + redirectUrl
            );
          } catch (err) {
            console.log('Error calling interface: ', err);
          }
          try {
            (
              window as any
            ).webkit.messageHandlers.ESignJavascriptInterface.postMessage(
              redirectUrl
            );
          } catch (err) {
            console.log('Error calling interface: ', err);
          }
        }
      },
    },
  };
  const url: string =
    actionTypeMapping[actionableType || 'DEFAULT'][action || 'default'];
  actionTypeMapping[actionableType || 'DEFAULT'].navigate(url);
}

export function getCustomerProfileObject(
  requiredVariables: string[],
  values: any
) {
  const profileObject: any = [];
  requiredVariables.forEach((field) => {
    if (hasOwnProperty(customerProfileReference, field)) {
      profileObject.push({
        ...customerProfileReference[field],
        value: values[field],
      });
    }
  });
  return profileObject;
}

export function getPartnerEmiInformation() {
  const state: any = store.getState();
  const customerData = get(state, 'customer', {});
  if (!isEmpty(customerData)) {
    const partnerCode = getCurrentPartnerCode(customerData);
    const loanResponse = LoanResponse.getInstance();
    const activeCreditProduct =
      CreditLineManager.getInstance().getActiveCreditLine();
    const lineType = activeCreditProduct.lineType;
    const lineTypeVariant = loanResponse.lineTypeVariant;
    let lineTypeMapping = lineType;
    if (lineTypeVariant && lineTypeVariant === 'BOLT') {
      lineTypeMapping = `${lineType}_${lineTypeVariant}`;
    }
    const currentPartnerConfig = (partnerBankConfig as any)[partnerCode];
    return get(
      currentPartnerConfig,
      `emiInformation.${lineTypeMapping || 'DEFAULT'}`,
      []
    );
  }
  return [];
}

export function getPartnerEmiInfoByCode(
  partnerCode: string,
  lineType?: string,
  lineTypeVariant?: string
) {
  if (!partnerCode) {
    return [];
  }
  const currentPartnerConfig = (partnerBankConfig as any)[partnerCode];
  let lineTypeMapping = lineType;
  if (lineTypeVariant && lineTypeVariant === 'BOLT') {
    lineTypeMapping = `${lineType}_${lineTypeVariant}`;
  }
  return get(
    currentPartnerConfig,
    `emiInformation.${lineTypeMapping || 'DEFAULT'}`,
    []
  );
}

export function getEmandateKey() {
  // TODO: Refactor this logic into a class for configs
  const state: any = store.getState();
  const customerData = get(state, 'customer', {});
  if (!isEmpty(customerData)) {
    const partnerCode = getCurrentPartnerCode(customerData);
    const emandateConfig =
      (partnerBankConfig as any)[partnerCode].emandateConfig || {};
    if (!isEmpty(emandateConfig)) {
      return process.env.NODE_ENV.indexOf('production') >= 0
        ? emandateConfig.live
        : emandateConfig.test;
    }
  }
  return '';
}

export function getEmandateKeyByPartnerCode(partnerCode: string) {
  const emandateConfig = (partnerBankConfig as any)[partnerCode].emandateConfig;
  if (!isEmpty(emandateConfig)) {
    return process.env.NODE_ENV.indexOf('production') >= 0
      ? emandateConfig.live
      : emandateConfig.test;
  }
  return '';
}

export function getRepaymentKey() {
  // TODO: Refactor this logic into a class for configs
  const state: any = store.getState();
  const customerData = get(state, 'customer', {});
  if (!isEmpty(customerData)) {
    const partnerCode = getCurrentPartnerCode(customerData);
    const repaymentConfig =
      (partnerBankConfig as any)[partnerCode].repaymentConfig || {};
    if (!isEmpty(repaymentConfig)) {
      return process.env.NODE_ENV.indexOf('production') >= 0
        ? repaymentConfig.live
        : repaymentConfig.test;
    }
  }
  return '';
}

export function getRepaymentKeyByPartnerCode(partnerCode: string) {
  const repaymentConfig = (partnerBankConfig as any)[partnerCode]
    .repaymentConfig;
  if (!isEmpty(repaymentConfig)) {
    return process.env.NODE_ENV.indexOf('production') >= 0
      ? repaymentConfig.live
      : repaymentConfig.test;
  }
}

export function getAutoDebitKey() {
  const state: any = store.getState();
  const customerData = get(state, 'customer', {});
  if (!isEmpty(customerData)) {
    const partnerCode = getCurrentPartnerCode(customerData);
    const autoDebitConfig =
      (partnerBankConfig as any)[partnerCode].autoDebitConfig || {};
    if (!isEmpty(autoDebitConfig)) {
      return process.env.NODE_ENV.indexOf('production') >= 0
        ? autoDebitConfig.live
        : autoDebitConfig.test;
    }
  }
  return '';
}

export function getAutoDebitKeyByPartnerCode(partnerCode: string) {
  const autoDebitConfig = (partnerBankConfig as any)[partnerCode]
    .repaymentConfig;
  if (!isEmpty(autoDebitConfig)) {
    return process.env.NODE_ENV.indexOf('production') >= 0
      ? autoDebitConfig.live
      : autoDebitConfig.test;
  }
}

export function getPartnerName() {
  const state: any = store.getState();
  const customerData = get(state, 'customer', {});
  if (!isEmpty(customerData)) {
    const partnerCode = getCurrentPartnerCode(customerData);
    if (partnerCode) {
      return (partnerBankConfig as any)[partnerCode].bankName || '';
    }
    return '';
  }
  return '';
}

export function calculateAge(dateOfBirth: string) {
  if (dateOfBirth) {
    const dateOfBirthObj = dateOfBirth.match(INPUT_DATE_REGEX);
    if (dateOfBirthObj) {
      const dateObj = {
        year: dateOfBirthObj[3],
        month: dateOfBirthObj[2],
        day: dateOfBirthObj[1],
      };
      const formattedDate = `${dateObj.year}-${dateObj.month}-${dateObj.day}`;
      const differenceInMs = Date.now() - new Date(formattedDate).getTime();
      const ageDate = new Date(differenceInMs);
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    } else {
      return 0;
    }
  }
  return 0;
}

export function isValidDate(date: string) {
  const dateArray = date.match(INPUT_DATE_REGEX);
  if (dateArray) {
    const year = Number(dateArray[3]);
    const month = Number(dateArray[2]) - 1;
    const day = Number(dateArray[1]);

    const refDate = new Date(year, month, day);

    const yearMatches = refDate.getFullYear() === year;
    const monthMatches = refDate.getMonth() === month;
    const dayMatches = refDate.getDate() === day;

    return yearMatches && monthMatches && dayMatches;
  } else {
    return false;
  }
}

export function getDuration(tenureKey: string) {
  const currentTenure: Tenure = head(
    tenure.filter((tenureItem: any) => tenureItem.value === tenureKey)
  );
  return currentTenure.tenure;
}

export function getDurationInMonths(tenureKey: string) {
  const currentTenure: Tenure = head(
    tenure.filter((tenureItem: any) => tenureItem.value === tenureKey)
  );
  return currentTenure.tenureInMonths;
}

export function getFormattedDateOfBirth(dateOfBirth: string) {
  const dateOfBirthObj = dateOfBirth.match(INPUT_DATE_REGEX);
  if (dateOfBirthObj) {
    const dateObj = {
      year: dateOfBirthObj[3],
      month: dateOfBirthObj[2],
      day: dateOfBirthObj[1],
    };
    return `${dateObj.year}-${dateObj.month}-${dateObj.day}`;
  }
  return '';
}

export const popupBlockChecker = {
  check(popupWindow: any) {
    const _scope = this;
    if (popupWindow) {
      if (/chrome/.test(navigator.userAgent.toLowerCase())) {
        setTimeout(() => {
          _scope._is_popup_blocked(_scope, popupWindow);
        }, 200);
      } else {
        popupWindow.onload = () => {
          _scope._is_popup_blocked(_scope, popupWindow);
        };
      }
    } else {
      return true;
    }
  },
  _is_popup_blocked(_scope: any, popupWindow: any) {
    if (popupWindow.innerHeight > 0 === false) {
      return true;
    } else {
      return false;
    }
  },
};

export const getBigCookie = async (navigationRequired = true) => {
  const loggedInUser = await isCustomerAuthenticated(authState());
  if (loggedInUser) {
    try {
      bus.$emit('loader', {
        show: true,
        message: 'Getting your details...',
      });
      await retrieveBigCookie(true, 'utils:getBigCookie');
      if (navigationRequired) {
        const ctxApplicationState: ApplicationState =
          ApplicationState.getInstance();
        const loanResponse: LoanResponse = LoanResponse.getInstance();
        navigateTo(ctxApplicationState, loanResponse);
        bus.$emit('loader');
      }
    } catch (err) {
      const { status = 0 } = err as any;
      if (![401, 502, 500].includes(status)) {
        bus.$emit('notify', {
          type: 'warning',
          showClose: false,
          autoClose: 5000,
          message:
            'Unable to retrieve your information. Please try after a while.',
        });
        bus.$emit('loader');
        doCtxLogout(false);
      }
      if ([401].includes(status)) {
        doCtxLogout(false);
        return;
        // TODO: Check otp expiry then make a call
        // TODO: Implement otp expiry properly
        const refreshToken = get(authState(), 'refresh_token', '');
        refreshAccessToken(refreshToken)
          .then(async (success) => {
            if (success) {
              try {
                await retrieveBigCookie();
                const ctxApplicationState: ApplicationState =
                  ApplicationState.getInstance();
                const loanResponse: LoanResponse = LoanResponse.getInstance();
                navigateTo(ctxApplicationState, loanResponse);
                bus.$emit('loader');
              } catch {
                emitSessionExpired();
              }
            } else {
              emitSessionExpired();
            }
          })
          .catch(() => {
            emitSessionExpired();
          })
          .finally(() => {
            bus.$emit('loader');
          });
      }
    } finally {
      bus.$emit('loader');
    }
  } else {
    doCtxLogout(false);
    bus.$emit('loader');
  }
};

const emitSessionExpired = () => {
  bus.$emit('loader');
  bus.$emit('syncEvent', 'WEB.SESSION.EXPIRED', { nonInteraction: true });
  bus.$emit('notify', {
    type: 'warning',
    message: 'Session expired. Login again.',
    autoClose: 4000,
    showClose: true,
  });
  doCtxLogout(false);
};

/**
 * Generates a UUID.
 * https://gist.github.com/jed/982883
 * @param {number|undefined=} a
 * @return {string}
 */
export const uuid = function b(a?: any): string {
  return a
    ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
    : `${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`.replace(/[018]/g, b);
};

export async function retrievePhoneFromJwt(jwt: any) {
  try {
    const decodedJWT = await decodeJsonWebToken(jwt);
    if (decodedJWT) {
      const phoneWithCode = decodedJWT.phone_number;
      const phoneParts = phoneWithCode.match(IND_PHONE_REGEX);
      return phoneParts[3];
    }
    return '';
  } catch {
    console.log('Log nothing');
    return '';
  }
}

export async function decodeJsonWebToken(jwt: any) {
  try {
    const VueJwtDecode = await import('vue-jwt-decode');
    const decodedJWT = VueJwtDecode.default.decode(jwt);
    return decodedJWT;
  } catch (err) {
    console.log('Error decoding JWT: ', err);
    return '';
  }
}

export function isMicroappsEnabled() {
  try {
    microapps.initialize();
    return true;
  } catch {
    return false;
  }
}

export function addScriptAsync(source: any) {
  return new Promise((resolve, reject) => {
    const scriptElem = document.createElement('script');
    scriptElem.setAttribute('src', source.url);
    scriptElem.setAttribute('type', source.type);
    scriptElem.onload = () => {
      resolve(true);
    };
    scriptElem.onerror = () => {
      reject(false);
    };
    document.head.appendChild(scriptElem);
  });
}

export function base64ToBlob(
  base64Data: string,
  mimeType: string,
  sliceSize = 512
) {
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let idx = 0; idx < slice.length; idx++) {
      byteNumbers[idx] = slice.charCodeAt(idx);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mimeType });
}

export function loadGoogleRecaptcha() {
  if (!isMicroappsEnabled()) {
    let captchaScript: any = document.head.querySelector(
      `script[src="${process.env.VUE_APP_RECAPTCHA_URL}"]`
    );
    if (!captchaScript) {
      captchaScript = document.createElement('script');
      captchaScript.src = process.env.VUE_APP_RECAPTCHA_URL;
      document.head.appendChild(captchaScript);
    }
    const recaptchaBadge: any = document.querySelector('.grecaptcha-badge');
    if (recaptchaBadge) {
      recaptchaBadge.style.display = 'block';
    }
  }
}

/**
 * Opens given url url in a new tab
 * @param {string} url
 * @return {void}
 */
export function openInNewTab(url: string): void {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    href: url,
  }).click();
}

function isNumberKey(evt: any) {
  const keyEvent = evt || window.event;
  const charCode = keyEvent.which ? keyEvent.which : keyEvent.keyCode;
  if (![48, 49, 50, 51, 52, 53, 54, 55, 56, 57].includes(charCode)) {
    evt.preventDefault();
    return false;
  }
  return true;
}

function isAlpha(evt: any) {
  const keyEvent = evt || window.event;
  const charCode = keyEvent.which ? keyEvent.which : keyEvent.keyCode;
  if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123)) {
    return true;
  } else {
    evt.preventDefault();
    return false;
  }
}

function isAlphaNumeric(evt: any) {
  const keyEvent = evt || window.event;
  const charCode = keyEvent.which ? keyEvent.which : keyEvent.keyCode;
  if (
    (charCode > 64 && charCode < 91) ||
    (charCode > 96 && charCode < 123) ||
    (charCode > 47 && charCode < 58)
  ) {
    return true;
  } else {
    evt.preventDefault();
    return false;
  }
}

function isAlphaNumericSpace(evt: any) {
  const keyEvent = evt || window.event;
  const charCode = keyEvent.which ? keyEvent.which : keyEvent.keyCode;
  if (
    (charCode > 64 && charCode < 91) ||
    (charCode > 96 && charCode < 123) ||
    [32, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57].includes(charCode)
  ) {
    return true;
  } else {
    evt.preventDefault();
    return false;
  }
}

function isQueryName(evt: any) {
  const keyEvent = evt || window.event;
  const charCode = keyEvent.which ? keyEvent.which : keyEvent.keyCode;
  if (
    (charCode > 64 && charCode < 91) ||
    (charCode > 96 && charCode < 123) ||
    [32, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 45].includes(charCode)
  ) {
    return true;
  } else {
    evt.preventDefault();
    return false;
  }
}

export function restrictInputValue(rule: string, evt: any) {
  switch (rule) {
    case 'alpha':
      return isAlpha(evt);
    case 'digits':
      return isNumberKey(evt);
    case 'alphaNumeric':
      return isAlphaNumeric(evt);
    case 'alphaNumericSpace':
      return isAlphaNumericSpace(evt);
    case 'queryName':
      return isQueryName(evt);
    default:
      return true;
  }
}

export async function getGeolocationPermissionStatus(): Promise<
  'denied' | 'granted' | 'prompt'
> {
  if ('permissions' in navigator) {
    return (await navigator.permissions.query({ name: 'geolocation' })).state;
  }

  return new Promise((resolve) => {
    navigator.geolocation.getCurrentPosition(
      async (position: any) => {
        updateGeolocation({ payload: position.coords });
        resolve('granted');
        await retrieveBigCookie(true, 'GeolocationCapture:status');
        bus.$emit('terminate-app-permission-popup');
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          resolve('denied');
        }
        resolve('granted');
      },
      {
        maximumAge: Infinity,
        timeout: 0,
      }
    );
  });
}

export function getGeolocation(
  data?: any,
  shouldCallBigCookie: boolean = false
): Promise<any> {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position: any) => {
        updateGeolocation({ payload: position.coords });
        const {
          coords: { latitude, longitude, accuracy },
        } = position;
        resolve({
          latitude,
          longitude,
          accuracy,
        });
        if (shouldCallBigCookie) {
          retrieveBigCookie(true, 'GeolocationCapture:granted');
        }
        bus.$emit('terminate-app-permission-popup', data);
      },
      (error: any) => {
        if (error.code === error.PERMISSION_DENIED) {
          reject('denied');
        }
        resolve('granted');
      },
      {
        maximumAge: Infinity,
        timeout: 10000,
      }
    );
  });
}

export function shouldRequestForGeolocation(appPermissions: any) {
  const locationPermission = appPermissions.find(
    (permission: any) => permission.name === 'LOCATION'
  );
  if (
    !locationPermission ||
    (locationPermission && locationPermission.type !== 'MANDATORY')
  ) {
    return false;
  }
  const applicationState = ApplicationState.getInstance();
  return applicationState.isApplicationStatusCodeBinary(
    BinaryPrecision.BETWEEN_EXCLUSIVE,
    ApplicationStatusCodeComponent.WHOLE,
    locationPermission.min,
    locationPermission.max
  );
}

export function getSanitizedError(error: any) {
  if (error instanceof Error || error instanceof String) {
    return [error];
  }
  return ['Error', error];
}

export function gsiOneTapPopup(google: any) {
  google && google.accounts && google.accounts.id.prompt();
}

export function getUtmSourceFromHost() {
  const hostName = document.location.hostname;
  if (hostName) {
    return HOST_TO_APP_SOURCE_MAPPING[hostName] || '';
  }
  return '';
}
